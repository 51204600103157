<script>
  import { createEventDispatcher } from "svelte";

  const dispatch = createEventDispatcher();

  export let data;
  $: home = data.hasOwnProperty("home")
    ? data.home
    : {
        title: "",
        lead: ""
      };
  $: navs = home.hasOwnProperty("nav") ? home.nav : [];

  function gotoSection(url) {
    dispatch("setsection", url);
  }
</script>

<style>
  h1.title {
    margin: 0;
    padding: 0;
    margin-top: 2.6vw;
    font-family: Impact;
    font-size: 55.2px;
    font-size: 5.6vw;
    line-height: 1;
    font-weight: 400;
    text-transform: uppercase;
    color: white;
    text-align: center;
  }
  h2.lead {
    margin: 0;
    padding: 0;
    margin-top: 1.06vw;
    font-size: 2.13vw;
    line-height: 1.4;
    font-weight: 700;
    text-align: center;
  }
  .navigation {
    box-sizing: border-box;
    width: 100%;
    margin-top: 1.5vw;
    padding: 0 9%;
    display: flex;
    flex-flow: row wrap;
  }
  .section-link {
    position: relative;
    flex: 0 0 calc(100% / 3);
  }
  .section-link:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
  .section-img {
    position: absolute;
    top: 5.3%;
    left: 5.3%;
    right: 5.3%;
    bottom: 5.3%;
    background-color: white;
    border-radius: 7.2%;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: contain;
  }
</style>

<div class="main home">
  <h1 class="title">
    {@html home.title}
  </h1>
  <h2 class="lead">
    {@html home.lead}
  </h2>
  <div class="navigation">
    {#each navs as nav}
      <div class="section-link">
        <div
          class="section-img"
          on:click={ev => gotoSection(nav.url)}
          style="background-image: url({`imgs/1024/${nav.img}`});
          background-size: {nav.imgsize || 'contain'}" />
      </div>
    {/each}
  </div>
</div>
