<script>
  import { onMount, tick } from "svelte";

  import Home from "./components/Home.svelte";
  import Section from "./components/Section.svelte";

  // import data from "./data.js";

  let data = { sections: [], home: { title: "", lead: "" } };
  let state = 0;
  let section = "";

  $: entry = { ...(data.sections.find(s => s.url == section) || {}) };
  $: home = data.hasOwnProperty("home") ? data.home : {};

  async function setSection({ detail }) {
    section = detail;
    await tick();
    state = 1;
  }
  async function goBack() {
    state = 0;
  }

  function loadScript(src) {
    return new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.onload = resolve;
      script.onerror = reject;
      script.src = src;
      document.head.append(script);
    });
  }

  onMount(async () => {
    // let response = await fetch("/data.json");
    // if (response.ok) {
    //   data = {
    //     ...data,
    //     ...(await response.json())
    //   };
    // }
    const db = await loadScript("data.js");
    if (window.db) {
      data = {
        ...data,
        ...window.db
      };
    }
  });
</script>

<style>
  main {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgb(178, 219, 143);
    background: linear-gradient(
      to left top,
      rgba(180, 197, 157, 1) 0%,
      rgba(180, 197, 157, 1) 48%,
      rgba(150, 175, 185, 1) 52%,
      rgba(150, 175, 185, 1) 100%
    );
    overflow: hidden;
  }
  :global(.main) {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
</style>

<main>
  {#if state == 0}
    <Home bind:data on:setsection={setSection} />
  {:else}
    <Section bind:home bind:entry on:back={goBack} />
  {/if}
</main>
