<script>
  import { Howl, Howler } from "howler";
  import { createEventDispatcher } from "svelte";
  import { onMount, onDestroy } from "svelte";

  const dispatch = createEventDispatcher();

  export let home = {};
  export let entry = {};
  let sound;

  function goBack() {
    dispatch("back");
  }

  onMount(async () => {
    sound =
      entry.hasOwnProperty("audio") && entry.audio
        ? new Howl({
            src: [...entry.audio.src],
            format: [
              ...entry.audio.src.reduce((acc, src) => {
                if (src.endsWith("m4a")) acc.push("aac");
                if (src.endsWith("mp3")) acc.push("mp3");
                if (src.endsWith("webm")) acc.push("ogg");
                return acc;
              }, [])
            ],
            html5: true,
            autoplay: true,
            loop: false,
            volume: entry.audio.volume || 1,
            onend() {
              goBack();
            },
            onloaderror(id, err) {
              console.log(id, err);
              goBack();
            },
            onplayerror(id, err) {
              console.log(id, err);
              goBack();
            }
          })
        : null;
  });
  onDestroy(async () => {
    Howler.unload();
  });
</script>

<style>
  h1.title {
    margin: 0;
    padding: 0;
    margin-top: 2.3vw;
    font-family: Impact;
    font-size: 39.15px;
    font-size: 3.95vw;
    line-height: 1;
    font-weight: 400;
    text-transform: uppercase;
    color: white;
    text-align: center;
  }
  h2.lead {
    margin: 0;
    padding: 0;
    margin-top: 1.25vw;
    font-size: 2.13vw;
    line-height: 1.4;
    font-weight: 700;
    text-align: center;
  }
  .section-people {
    box-sizing: border-box;
    width: 100%;
    margin-top: 4.8vw;
    padding: 0 9%;
    display: flex;
    flex-flow: row wrap;
  }
  .person-item {
    box-sizing: border-box;
    flex: 0 0 50%;
    padding: 0 2.5vw;
  }
  .person-image {
    width: 100%;
    position: relative;
    border-radius: 7.2%;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .person-image:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
  .person-caption {
    width: 100%;
    font-size: 2.27vw;
    font-weight: 300;
    line-height: 1.4;
    text-align: center;
    margin-top: 1.5vw;
  }
  .person-name {
    font-weight: 700;
  }
  .back-button {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 15vw;
    text-align: center;
    font-size: 2.27vw;
    font-weight: 700;
    color: white;
    text-transform: uppercase;
    padding: 0.5vw;
    padding-bottom: 2vw;
  }
</style>

<div class="main section">
  <h1 class="title">
    {@html home.title}
  </h1>
  <h2 class="lead">
    {@html entry.lead}
  </h2>
  <div class="section-people">
    {#each entry.people || [] as person}
      <div class="person-item">
        <div
          class="person-image"
          style="background-image: url({`imgs/1024/${person.img}`});" />
        <div class="person-caption">
          <div class="person-name">{person.name}</div>
          <div class="person-job">
            {@html person.jobtitle}
          </div>
        </div>
      </div>
    {/each}
  </div>
  <div class="back-button" on:click={goBack}>zurück</div>
</div>
